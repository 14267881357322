import React, { useEffect, useState, useRef } from "react";
import socketIO from "socket.io-client";
import { useForm } from "react-hook-form";
import { useAuth } from "../../../context/AuthContext";
import { getChatsUsersInRoom, getUsersChatsByUserId } from "../../../services/chat";

const socket = socketIO.connect(process.env.REACT_APP_SOCKET_URI);

export default function Chat() {
  const { user } = useAuth();
  const [userList, setUserList] = useState([]);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState({});
  const [chatUser, setChatUser] = useState();

  const messagesEndRef = useRef(null);

  const { register, handleSubmit, reset } = useForm();

  const handleShowChat = async (usr) => {
    setChatUser(usr.id);
    const chat = await getChatsUsersInRoom(user.id, usr.id);
    setMessages(chat);
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleFormSubmit = ({ text }) => {
    setMessages([
      ...messages,
      {
        text: text,
        createdAt: new Date(),
        user: {
          _id: user.id,
          name: `${user.firstName} ${user.lastName}`,
        },
      },
    ]);
    reset();
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    socket.emit("new-message", {
      message: {
        text: text,
        createdAt: new Date(),
        user: {
          _id: user.id,
          name: `${user.firstName} ${user.lastName}`,
        },
      },
      to: chatUser,
      roomId: user.id,
    });
  };
  useEffect(() => {
    socket.on("new-message", (message) => {
      setNewMessage(message);
    });
    return () => {
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    if (user?.id && !userList.length) {
      socket.emit("join-room", { roomId: user.id });
      getUsersChatsByUserId().then((res) => setUserList(res));
    }
  }, [user?.id, userList]);

  useEffect(() => {
    if (newMessage.id && newMessage.sender.id === chatUser) {
      setMessages((prevMsg) => [
        ...prevMsg,
        {
          _id: newMessage.id,
          text: newMessage.text,
          createdAt: newMessage.created_at,
          user: { _id: newMessage.sender.id, name: `${newMessage.sender.firstName} ${newMessage.sender.lastName}` },
        },
      ]);
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [newMessage, chatUser]);
  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full flex px-4">
          {/*** */}
          <div className="w-full xl:w-3/12 mb-12 xl:mb-0 px-4">
            <div className="relative h-600-px flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded overflow-y-auto">
              <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
                <div className="flex flex-wrap items-center">
                  <div className="relative w-full max-w-full flex-grow flex-1">
                    <h2 className="text-blueGray-700 text-xl font-semibold">Client list</h2>
                  </div>
                </div>
              </div>
              <div className="p-4 flex-auto">
                <div className="flex flex-col relative h-350-px">
                  {userList.map((usr) => (
                    <button
                      type="button"
                      key={usr.id}
                      className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2 my-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                      onClick={() => handleShowChat(usr)}
                    >
                      {`${usr.firstName} ${usr.lastName}`}
                    </button>
                  ))}
                </div>
              </div>
            </div>
          </div>
          {/* /****** */}
          <div className="w-full xl:w-9/12 px-4">
            <div className="relative h-600-px flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
              <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
                <div className="flex flex-wrap items-center">
                  <div className="relative w-full max-w-full flex-grow flex-1">
                    <h2 className="text-blueGray-700 text-xl font-semibold">Chat</h2>
                  </div>
                </div>
              </div>
              <div className="p-4 flex-auto flex-col">
                <div className="relative overflow-y-auto h-420-px">
                  <div className="w-full px-5 flex flex-col justify-between">
                    <div className="flex flex-col mt-5">
                      {messages.map((message) => (
                        <div key={message._id} className={`flex mb-4 ${message.user._id === user.id ? "justify-start" : "justify-end"}`}>
                          <div className={`mr-2 py-3 px-4 rounded-bl-3xl rounded-tl-3xl rounded-tr-xl text-white ${message.user._id === user.id ? "bg-blue-400" : "bg-gray-400"}`}>
                            {message.text}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                {messages.length ? (
                  <form ref={messagesEndRef} className="space-y-6" onSubmit={handleSubmit(handleFormSubmit)}>
                    <div className="flex py-5">
                      <input className="w-full bg-gray-300 py-4 px-3 rounded-xl" type="text" {...register("text")} placeholder="type your message here..." required />
                      <button
                        type="submit"
                        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm ml-3 px-5 py-2 my-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                      >
                        Send
                      </button>
                    </div>
                  </form>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
