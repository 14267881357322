import { authenticatedApi } from "./base";

export const loginAsAdmin = async (email) => {
  try {
    const emailParam = new URLSearchParams({ email });
    const authApi = await authenticatedApi();
    const { data } = await authApi.get(`${process.env.REACT_APP_SERVER_URI}/auth/isAdmin?${emailParam}`);
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getUser = async (uid) => {
  try {
    const authApi = await authenticatedApi();
    const { data } = await authApi.get(`${process.env.REACT_APP_SERVER_URI}/users/${uid}`);
    return data;
  } catch (error) {
    console.log(error);
  }
};
