import React, { useState, useEffect, useCallback } from "react";
import UsersCardTable from "./UsersCardTable";
import { users as getUsers } from "../../../services/users";

export default function Users() {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    getUsers()
    .then(data => setUsers(data))
    .catch(err => console.log('error ', err));
  }, []);

  const addUserToList = useCallback((user) => {
    const newList = [...users, user];
    setUsers(newList);
  }, [users]);

  return (
    <div className="flex flex-wrap mt-4">
      <div className="w-full mb-12 px-4">
        <UsersCardTable
          users={users}
          addUserToList={addUserToList}
        />
      </div>
    </div>
  );
}
