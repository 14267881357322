
import React from "react";
import { BrowserRouter, Switch, Redirect } from "react-router-dom";
import { AuthProvider } from './context/AuthContext';
// layouts
import Admin from "layouts/Admin";
import Hotel from "layouts/Hotel";
import Pms from "layouts/Pms";
import Auth from "layouts/Auth";
import ProtectedRoute from "./components/ProtectedRoute";
import PublicRoute from "./components/PublicRoute";

function App () {
return (
  <BrowserRouter>
    <AuthProvider>
      <Switch>
        <ProtectedRoute path="/admin" component={Admin} />
        <ProtectedRoute path="/pms" component={Pms} />
        <ProtectedRoute path="/hotel" component={Hotel} />
        <PublicRoute path="/auth" component={Auth} />
        <Redirect from="*" to="/auth/login" />
      </Switch>
    </AuthProvider>
  </BrowserRouter>
);
}
export default App;