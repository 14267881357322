import React, { useEffect } from "react";

// components

import CardBarChart from "components/Cards/CardBarChart.js";
import CardSocialTraffic from "components/Cards/CardSocialTraffic.js";
import { getReviewsStatistics } from "services/hotels";
import { useAuth } from "context/AuthContext";

export default function Dashboard() {
  const { hotel } = useAuth();
  useEffect(() => {
    (async () => {
      if (hotel?.id) {
        await getReviewsStatistics(hotel.id);
      }
    })();
  }, [hotel?.id]);

  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full xl:w-6/12 px-4">
          <CardBarChart />
        </div>
        <div className="w-full xl:w-6/12 px-4">
          <CardSocialTraffic />
        </div>
      </div>
    </>
  );
}
