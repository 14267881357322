import { useAuth } from "context/AuthContext";
import React, { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { updateReviewsQuestions } from "services/hotels";
import { deleteReviewsQuestions } from "services/hotels";
import { createReviewsQuestions } from "services/hotels";
import { getReviewsQuestions } from "services/hotels";

const mockData = {
  review: {
    label: "Adventure Retreat",
    description: "The hotel provided the perfect base for our adventure retreat. The proximity to outdoor activities and comfortable accommodations added to the overall experience.",
  },
  reviewQuestions: [{ question: "Which outdoor activities did you engage in during your stay?" }],
};

function dirtyValues(dirtyFields, allValues) {
  // If *any* item in an array was modified, the entire array must be submitted, because there's no way to indicate
  // "placeholders" for unchanged elements. `dirtyFields` is `true` for leaves.
  if (dirtyFields === true || Array.isArray(dirtyFields)) return allValues;
  // Here, we have an object
  return Object.fromEntries(Object.keys(dirtyFields).map((key) => [key, dirtyValues(dirtyFields[key], allValues[key])]));
}

function removeEmptyKeys(obj) {
  for (const key in obj) {
    if (typeof obj[key] === "object" && obj[key] !== null) {
      // Recursively call the function for nested objects
      removeEmptyKeys(obj[key]);

      // Check if the object is empty after recursion and delete the key
      if (Object.keys(obj[key]).length === 0) {
        delete obj[key];
      }
    } else if (obj[key] === null || obj[key] === undefined || obj[key] === "" || (Array.isArray(obj[key]) && obj[key].length === 0)) {
      // Delete keys with empty values
      delete obj[key];
    }
  }
  return obj;
}
export default function CreateReviews() {
  const { hotel } = useAuth();
  const [reviews, setReviews] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [error, setError] = useState("");
  const [reviewId, setReviewId] = useState(null);
  const [isFetchLoading, setIsFetchLoading] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { isLoading, dirtyFields },
    getValues,
  } = useForm({
    defaultValues: {
      review: {
        label: "",
        description: "",
      },
      reviewQuestions: [{ question: "" }],
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "reviewQuestions",
  });

  const handleReviewCreate = async (formData) => {
    if (Object.keys(dirtyValues(dirtyFields, formData)).length === 0 && isEdit) {
      setError("No changes were found in your review, please consider doing some changes before submitting next time");
      console.log("Error while updating");
      return;
    } else if (Object.keys(dirtyValues(dirtyFields, formData)).length && isEdit) {
      const formatted = removeEmptyKeys(dirtyValues(dirtyFields, formData));
      const body = { ...formatted, reviewId };
      const res = await updateReviewsQuestions(body);
      if (res.success === true) {
        console.log("Data updated");
        getReviewsQuestions(hotel.id).then((data) => setReviews(data));
        reset({
          review: {
            label: "",
            description: "",
          },
          reviewQuestions: [{ question: "" }],
        });
      }
    } else {
      const res = await createReviewsQuestions(hotel.id, formData);
      if (res) {
        getReviewsQuestions(hotel.id).then((data) => setReviews(data));
        reset();
      }
    }
  };

  const handleReviewDelete = async (reviewId, reviewQuestionsIds) => {
    const res = await deleteReviewsQuestions(reviewId, reviewQuestionsIds);
    if (res.success === true) {
      getReviewsQuestions(hotel.id).then((data) => setReviews(data));
    }
  };
  useEffect(() => {
    setIsFetchLoading(true);
    if (hotel) {
      getReviewsQuestions(hotel.id).then((data) => {
        setReviews(data);
        setIsFetchLoading(false);
      });
    }
  }, [hotel?.id]);

  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full lg:w-12/12">
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
            <form className="mb-4 px-4 py-4" onSubmit={handleSubmit(handleReviewCreate)}>
              <div className="flex flex-1 justify-between items-center mb-6">
                <h1 className="text-center text-xl font-bold">Create Review Question</h1>
                {isEdit ? (
                  <div>
                    <button
                      className=" bg-purple-500 text-white active:bg-purple-500 font-bold uppercase text-3xl px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                      type="submit"
                    >
                      Update
                    </button>
                    <button
                      className=" bg-red-500 text-white active:bg-red-500 font-bold uppercase text-3xl px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => {
                        setIsEdit(false);
                        reset({
                          review: {
                            label: "",
                            description: "",
                          },
                          reviewQuestions: [{ question: "" }],
                        });
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                ) : (
                  <button
                    className=" bg-purple-500 text-white active:bg-purple-500 font-bold uppercase text-3xl px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                    type="submit"
                  >
                    Create
                  </button>
                )}
              </div>
              <div className="flex flex-wrap">
                <div className="w-full px-4">
                  <div className="relative w-full mb-4">
                    <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                      Label
                    </label>
                    <input
                      type="text"
                      className="mb-4 border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Give label to review so you can find it easy to filter them"
                      {...register("review.label")}
                      required
                    />
                    <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                      Description
                    </label>
                    <input
                      type="text"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Give a small description about your review"
                      {...register("review.description")}
                      required
                    />
                  </div>
                </div>
                <div className="w-full px-4">
                  <div className="relative w-full mb-4">
                    <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                      Question
                    </label>

                    {fields.map((field, index) => {
                      return (
                        <div key={field.id} className="w-full flex justify-between flex-wrap mb-4">
                          <input
                            type="text"
                            className={`${
                              index > 0 && "mr-4"
                            } border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring flex-1 ease-linear transition-all duration-150`}
                            placeholder="Question"
                            {...register(`reviewQuestions.${index}.question`)}
                            required
                          />
                          {index > 0 && (
                            <button
                              className="bg-red-500 text-white active:bg-red-500 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                              onClick={() => remove(index)}
                            >
                              Remove
                            </button>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </form>
            <div className="flex justify-center px-4">
              <button
                className="mb-4 bg-purple-500  text-white active:bg-purple-500 font-bold uppercase text-base px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                onClick={() => append()}
              >
                Add question
              </button>
            </div>

            {/* ---------------------------------------- QUESTIONS ---------------------------------------- */}
            <div className="w-full">
              <h1 className="font-bold uppercase text-xl mb-6 text-center">Your Reviews Questions</h1>
              <div className="h-full space-x-4 flex flex-wrap">
                {isLoading || isFetchLoading ? (
                  <div className="w-full">
                    <p className="py-4 px-4 text-center text-3xl font-bold">Loading Reviews, Please a moment...</p>
                  </div>
                ) : reviews.length > 0 ? (
                  reviews?.map((review) => {
                    return (
                      <div className="w-full m-2 py-4 px-4 space-y-2 border-2 rounded-xl mb-4" key={review.id}>
                        <div className="flex w-full items-center justify-between">
                          <h1 className="font-bold text-2xl mb-6 self-center">{review.label}</h1>
                          <div className="flex justify-between items-center space-x-2">
                            <button
                              onClick={() => {
                                setIsEdit(true);
                                setReviewId(review.id);
                                reset({
                                  review: {
                                    label: review.label,
                                    description: review.description,
                                  },
                                  reviewQuestions: review.questions,
                                });
                              }}
                            >
                              <i className="fas fa-solid fa-pen text-xl mr-4"></i>
                            </button>
                            <button onClick={() => handleReviewDelete(review.id, null)}>
                              <i className="fas fa-solid fa-trash text-2xl"></i>
                            </button>
                          </div>
                        </div>
                        <p className="mb-4">
                          <span className="font-bold">Description: </span> {review.description}
                        </p>
                        <div className="flex flex-col">
                          <h1 className="font-bold mb-4">Questions:</h1>
                          {review?.questions?.map((question) => (
                            <div key={question.id} className="flex items-center justify-between">
                              <p className="border-2 px-2 py-4 mb-4 self-center">{question.question}</p>
                              <button onClick={() => handleReviewDelete(null, [question.id])}>
                                <i className="fas fa-solid fa-trash text-lg text-red-600"></i>
                              </button>
                            </div>
                          ))}
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <p>You don't have any reviews</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
