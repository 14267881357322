import { supabase } from "config/supabase";
import { authenticatedApi } from "./base";

export const getUsersChatsByUserId = async () => {
  try {
    const authApi = await authenticatedApi();
    const { data } = await authApi.get(`${process.env.REACT_APP_SERVER_URI}/chat/users-by-user`);
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getChatsUsersInRoom = async (roomId, userId) => {
  const params = new URLSearchParams({
    roomId,
    userId,
  });
  const pathname = "/chat/hotel-chat-by-room-user";
  const url = `${process.env.REACT_APP_SERVER_URI}${pathname}?${params}`;
  try {
    const authApi = await authenticatedApi();
    const { data } = await authApi.get(url);
    return data;
  } catch (error) {
    console.log(error);
  }
};
