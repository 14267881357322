import React, { useEffect } from "react";
import { useForm, useFieldArray } from "react-hook-form";

const END_POINTS = [
  // {title: 'Hotels with params', key: 'HOTELS_WITH_PARAMS'},
  {title: 'Check availability', key: 'GET_AVAILABILITY'},
  {title: 'Get rooms', key: 'GET_ROOMS'},
  {title: 'Get one room', key: 'GET_ONE_ROOM'},
  {title: 'Create reservation', key: 'RESERVATION'},
  {title: 'Checkin', key: 'CHECKIN'},
  {title: 'Checkout', key: 'CHECKOUT'},
];

const RequestCmp = ({control, endPointIndex, register}) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: `pmsEndPoints[${endPointIndex}].request`,
  });

  return (
    <div className="flex flex-1 flex-col">
      <div className="flex">
        <span className="block mr-2 text-sm font-medium text-gray-900 dark:text-gray-300">Request: </span>
        <button
          type="button"
          className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-1 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          onClick={() => append()}
        >
          Add
        </button>
      </div>
      {fields.map((field, index) => (
        <div className="flex flex-col justify-center mr-2" key={field.id}>
          <label htmlFor="endpointName" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">#{index + 1}</label>
          <div className="flex flex-1 items-center">
            <div className="flex flex-1">
              <input
                type="text"
                {...register(`pmsEndPoints[${endPointIndex}].request[${index}].name`)}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full mr-2 p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                required
              />
            </div>
            <button
              type="button"
              className="text-white bg-red-500 hover:bg-red-400 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-1 text-center"
              onClick={remove}
            >
              delete
            </button> 
          </div>
        </div>
      ))}
    </div>
  );
};

const ResponseCmp = ({control, endPointIndex, register}) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: `pmsEndPoints[${endPointIndex}].response`,
  });

  return (
    <div className="flex flex-1 flex-col">
      <div className="flex">
        <span className="block mr-2 text-sm font-medium text-gray-900 dark:text-gray-300">Response: </span>
        <button
          type="button"
          className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-1 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          onClick={() => append()}
        >
          Add
        </button>
      </div>
      {fields.map((field, index) => (
        <div className="flex flex-col justify-center mr-2" key={field.id}>
          <label htmlFor="endpointName" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">#{index + 1}</label>
          <div className="flex flex-1 items-center">
            <div className="flex flex-1">
              <input
                type="text"
                {...register(`pmsEndPoints.${endPointIndex}.response.${index}.name`)}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full mr-2 p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                required
              />
            </div>
            <button
              type="button"
              className="text-white bg-red-500 hover:bg-red-400 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-1 text-center"
              onClick={remove}
            >
              delete
            </button> 
          </div>
        </div>
      ))}
    </div>
  );
};

const PmsForm = ({ onSubmit, initValue}) => {
  const { register, handleSubmit, reset, control } = useForm();

  useFieldArray({
    control,
    name: "pmsEndPoints",
  });

  useEffect(() => {
    if (initValue?.id) {
      let defautlVals = [];
      Object.entries(initValue).forEach(([_, values]) => {
        if (Array.isArray(values)) defautlVals = values;
      });
      console.log(defautlVals);
      reset({
        pmsName: initValue.pmsName,
        regiterCondition: initValue.regiterCondition,
        pmsApiUrl: initValue.pmsApiUrl,
        pmsEndPoints: defautlVals,
      });
    }
  }, [initValue, reset]);

  const handleFormSubmit = async (formData) => {
    console.log(formData);
    await onSubmit(formData);
    reset();
  };

  return (
    <>
    <form className="space-y-6" onSubmit={handleSubmit(handleFormSubmit)}>
      <div className="flex flex-row">
        <div className="flex-1">
          <label htmlFor="pmsName" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Pms name</label>
          <input
            type="text"
            name="pmsName"
            {...register("pmsName")}
            id="pmsName"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
            placeholder="Thais"
            required
          />
        </div>
        <div className="flex-1 px-2">
          <label htmlFor="regiterCondition" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Connect type</label>
          <select
            type="text"
            name="regiterCondition"
            {...register("regiterCondition")}
            id="role"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
            required
          >
            <option value="REGISTER_WITH_HOTEL_ID">Connect with hotel ID</option>
            <option value="REGISTER_WITH_USER_PASSWORD">Connect with username and password</option>
            <option value="REGISTER_WITH_TOKEN">Connect with Token</option>
          </select>
        </div>
      </div>
      <div className="flex flex-row">
        <div className="flex-1">
          <label htmlFor="pmsApiUrl" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Pms api url</label>
          <input
            type="url"
            name="pmsApiUrl"
            {...register("pmsApiUrl")}
            id="pmsApiUrl"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
            placeholder="https://demo.thais-hotel.com/hub/api/partner"
            required
          />
        </div>
      </div>
      {/* <div className="flex flex-1 items-center">
        <span className="block mr-2 text-sm font-medium text-gray-900 dark:text-gray-300">Pms api endpoints: </span>
      </div>
      {END_POINTS.map((endPoint, index) => (
        <div className="flex flex-col flex-1 justify-center" key={endPoint.key}>
          <label htmlFor="endpointName" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">{endPoint.title}</label>
          <div className="flex flex-1 items-center">
            <div className="flex flex-1">
              <input
                type="text"
                name="endpointName"
                {...register(`pmsEndPoints[${index}].pmsEndpoint`)}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full mr-2 p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                required
              />
            </div>
            <div className="flex flex-1">
              <select
                name="endpointMethode"
                {...register(`pmsEndPoints[${index}].pmsHttpMethode`)}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full mr-2 p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                required
              >
                <option value="GET">GET</option>
                <option value="POST">POST</option>
                <option value="PUT">PUT</option>
              </select>
            </div>
          </div>
          <div className="flex flex-1 pt-2">
            <div className="flex flex-1">
              <RequestCmp endPointIndex={index} register={register} control={control} />
            </div>
            <div className="flex flex-1">
              <ResponseCmp endPointIndex={index} register={register} control={control} />
            </div>
          </div>
        </div>
      ))} */}
      <button
        type="submit"
        className="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
      >
        Save
      </button>
    </form>
    </>
  );
};

export default PmsForm;