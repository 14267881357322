import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// components
import AdminNavbar from "components/Navbars/AdminNavbar";
import HeaderStats from "components/Headers/HeaderStats";
import FooterAdmin from "components/Footers/FooterAdmin";
// views
import Dashboard from "views/hotel/Dashboard";
import Profile from "views/hotel/profile";

import Reservations from "views/hotel/Reservations";
import Rooms from "views/hotel/Rooms";
import Chat from "views/hotel/Chat";
import CreateReviews from "views/hotel/Review/CreateReviews";

export default function Admin() {
  return (
    <>
      <div className="relative bg-blueGray-100 min-h-screen">
        <AdminNavbar />
        {/* Header */}
        <HeaderStats showState={window.location.href.indexOf("/hotel/dashboard") !== -1} />
        <div className="px-4 md:px-10 mx-auto w-full -m-24 h-full">
          <Switch>
            <Route path="/hotel/dashboard" exact component={Dashboard} />
            <Route path="/hotel/profile" exact component={Profile} />
            <Route path="/hotel/reservations" exact component={Reservations} />
            <Route path="/hotel/rooms" exact component={Rooms} />
            <Route path="/hotel/chat" exact component={Chat} />
            <Route path="/hotel/create-reviews" exact component={CreateReviews} />
            <Redirect from="/hotel" to="/hotel/dashboard" />
          </Switch>
        </div>
        <FooterAdmin />
      </div>
    </>
  );
}
