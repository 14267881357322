import React, { useCallback } from "react";
import RoomForm from "./RoomForm";
import { updateRoom, uploadMedia } from "../../../services/rooms";

const PmsModalUpdate = ({ hideModal, updateRoomInList, initValue }) => {
  const onSubmit = useCallback(
    async ({ mediasData, ...data }) => {
      const response = await updateRoom({
        id: initValue.id,
        roomPmsId: initValue.roomPmsId ?? initValue.id,
        beds: initValue.beds,
        doorId: initValue.doorId,
        name: initValue.name,
        ...data,
      });
      const mediaRes = await uploadMedia(mediasData);
      if (response.error) alert(response.error);
      else {
        updateRoomInList(response);
        alert("Room updated successfully");
      }
      hideModal();
    },
    [hideModal, initValue.id, initValue.roomPmsId, updateRoomInList, initValue.beds, initValue.doorId, initValue.name]
  );

  return (
    <div id="update-room-modal" tabIndex="-1" aria-hidden="true" className="hidden overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full">
      <div className="relative p-4 h-full" style={{ width: "70%" }}>
        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
          <div className="py-6 px-6 lg:px-8">
            <div className="flex flex-row items-center mb-4">
              <h3 className="text-xl font-medium text-gray-900 dark:text-white">Update room</h3>
              <button
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
                onClick={hideModal}
              >
                <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>
            </div>
            <RoomForm initValue={initValue} onSubmit={onSubmit} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PmsModalUpdate;
