import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import ChangePassword from "views/auth/ChangePassword";
// views
import Login from "views/auth/Login.js";
import ResetPasword from "views/auth/ResetPassword";

export default function Auth() {
  return (
    <main>
      <section className="relative w-full h-full py-40 min-h-screen">
        <div
          className="absolute top-0 w-full h-full bg-blueGray-800 bg-no-repeat bg-full"
          style={{
            background: "linear-gradient(89.6deg, #9928F4 -43.06%, #CB40A7 119.19%)",
          }}
        ></div>
        <Switch>
          <Route path="/auth/login" exact component={Login} />
          <Route path="/auth/reset-password" exact component={ResetPasword} />
          <Route path="/auth/change-password" exact component={ChangePassword} />
          <Redirect from="/auth" to="/auth/login" />
        </Switch>
      </section>
    </main>
  );
}
