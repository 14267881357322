/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef } from "react";
import { createPopper } from "@popperjs/core";
import { useAuth } from "../../context/AuthContext";
import { Link } from "react-router-dom";

const adminMenu = [
  {
    label: "Dashboard",
    url: "/admin/dashboard",
    icon: "fa-tv",
  },
  {
    label: "Users",
    url: "/admin/users",
    icon: "fa-users",
  },
  {
    label: "Pms",
    url: "/admin/pms",
    icon: "fa-users",
  },
  {
    label: "Hotels",
    url: "/admin/hotels",
    icon: "fa-hotel",
  },
  {
    label: "Reservations",
    url: "/admin/reservations",
    icon: "fa-hotel",
  },
];

const pmsMenu = [
  // {
  //   label: "Dashboard",
  //   url: "/pms/dashboard",
  //   icon: "fa-tv",
  // },
  {
    label: "Profile",
    url: "/pms/profile",
    icon: "fa-users",
  },
  {
    label: "Hotels",
    url: "/pms/hotels",
    icon: "fa-hotel",
  },
];

const hotelMenu = [
  // {
  //   label: "Dashboard",
  //   url: "/hotel/dashboard",
  //   icon: "fa-tv",
  // },
  {
    label: "Profile",
    url: "/hotel/profile",
    icon: "fa-users",
  },
  {
    label: "Rooms",
    url: "/hotel/rooms",
    icon: "fa-hotel",
  },
  {
    label: "Reservations",
    url: "/hotel/reservations",
    icon: "fa-hotel",
  },
  {
    label: "Chat",
    url: "/hotel/chat",
    icon: "fa-comment",
  },
  {
    label: "Reviews",
    url: "/hotel/create-reviews",
    icon: "fa-solid fa-star",
  },
];

const UserDropdown = () => {
  const { logout } = useAuth();
  const { user } = useAuth();
  // dropdown props
  const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);
  const btnDropdownRef = useRef();
  const popoverDropdownRef = useRef();

  const openDropdownPopover = () => {
    createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
      placement: "bottom-start",
    });
    setDropdownPopoverShow(true);
  };
  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false);
  };

  useEffect(() => {
    const closeDropdownOnClickOutside = (event) => {
      if (popoverDropdownRef.current && !popoverDropdownRef.current.contains(event.target) && btnDropdownRef.current && !btnDropdownRef.current.contains(event.target)) {
        setDropdownPopoverShow(false);
      }
    };

    document.addEventListener("mousedown", closeDropdownOnClickOutside);

    return () => {
      document.removeEventListener("mousedown", closeDropdownOnClickOutside);
    };
  }, []);

  return (
    <>
      <a
        className="text-blueGray-500 block"
        href="#user"
        ref={btnDropdownRef}
        onClick={(e) => {
          e.preventDefault();
          dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover();
        }}
      >
        <div className="items-center flex">
          <span className="w-12 h-12 text-sm text-purple-700 bg-purple-200 inline-flex items-center justify-center rounded-full">
            {/* <img
              alt="..."
              className="w-full rounded-full align-middle border-none shadow-lg"
              src={require("assets/img/team-1-800x800.jpg").default} // change this with hotel image
            /> */}
            {user?.role === "ADMIN" && "A"}
            {user?.role === "HOTEL" && "H"}
            {user?.role === "PMS" && "P"}
          </span>
        </div>
      </a>
      <div ref={popoverDropdownRef} className={(dropdownPopoverShow ? "block " : "hidden ") + "bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48"}>
        <ul className="md:flex-col md:min-w-full flex flex-col list-none px-4">
          {user?.role === "PMS"
            ? pmsMenu.map((item) => (
                <li key={item.label} className="items-center">
                  <Link
                    className={
                      "text-xs uppercase py-3 font-bold block " +
                      (window.location.href.indexOf(item.url) !== -1 ? "text-purple-500 hover:text-purple-500" : "text-blueGray-700 hover:text-blueGray-500")
                    }
                    to={item.url}
                  >
                    <i className={`fas ${item.icon} mr-2 text-sm ` + (window.location.href.indexOf(item.url) !== -1 ? "opacity-75" : "text-blueGray-300")}></i> {item.label}
                  </Link>
                </li>
              ))
            : null}
          {user?.role === "ADMIN"
            ? adminMenu.map((item) => (
                <li key={item.label} className="items-center">
                  <Link
                    className={
                      "text-xs uppercase py-3 font-bold block " +
                      (window.location.href.indexOf(item.url) !== -1 ? "text-purple-500 hover:text-purple-500" : "text-blueGray-700 hover:text-blueGray-500")
                    }
                    to={item.url}
                  >
                    <i className={`fas ${item.icon} mr-2 text-sm ` + (window.location.href.indexOf(item.url) !== -1 ? "opacity-75" : "text-blueGray-300")}></i> {item.label}
                  </Link>
                </li>
              ))
            : null}
          {user?.role === "HOTEL"
            ? hotelMenu.map((item) => (
                <li key={item.label} className="items-center">
                  <Link
                    className={
                      "text-xs uppercase py-3 font-bold block " +
                      (window.location.href.indexOf(item.url) !== -1 ? "text-purple-500 hover:text-purple-500" : "text-blueGray-700 hover:text-blueGray-500")
                    }
                    to={item.url}
                  >
                    <i className={`fas ${item.icon} mr-2 text-sm ` + (window.location.href.indexOf(item.url) !== -1 ? "opacity-75" : "text-blueGray-300")}></i> {item.label}
                  </Link>
                </li>
              ))
            : null}
          <li className="items-center">
            <a href="#" className={"text-xs uppercase py-3 font-bold block " + "text-purple-500 hover:text-purple-500"} onClick={logout}>
              Logout
            </a>
          </li>
        </ul>
      </div>
    </>
  );
};

export default UserDropdown;
