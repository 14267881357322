import React, { useEffect, useState, useCallback } from "react";
import { useAuth } from "../../../context/AuthContext";
import { mergedRooms as getRooms } from "../../../services/rooms";
import RoomModalUpdate from "./RoomModalUpdate";

export default function Rooms() {
  const { user } = useAuth();
  const [rooms, setRooms] = useState([]);
  const [error, setError] = useState("");
  const [selectedRoom, setSelectedRoom] = useState({});

  const modalUpdateEl = document.getElementById("update-room-modal");

  useEffect(() => {
    if (user?.hotel?.hotelId) {
      getRooms(user.hotel.hotelId)
        .then((data) => {
          if (data.length) setRooms(data);
          else setError("No room available");
        })
        .catch((err) => console.log("error ", err));
    }
  }, [user?.hotel?.hotelId]);

  const showUpdateRoom = useCallback(
    (room) => {
      setSelectedRoom(room);
      const modal = new window.Modal(modalUpdateEl);
      modal.show();
    },
    [modalUpdateEl]
  );

  const hideUpdateModal = useCallback(() => {
    const modal = new window.Modal(modalUpdateEl);
    modal.hide();
    setSelectedRoom({});
    const modalBackdrops = document.querySelectorAll("[modal-backdrop]");
    modalBackdrops.forEach((element) => element.remove());
  }, [modalUpdateEl]);

  const updateRoomInList = useCallback(
    (updatedRoom) => {
      const newList = [...rooms];
      const hotelIndex = rooms.findIndex((room) => room.id === updatedRoom.id);
      newList[hotelIndex] = updatedRoom;
      setRooms(newList);
    },
    [rooms]
  );
  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full lg:w-12/12 px-4">
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
            <div className="rounded-t bg-white mb-0 px-6 py-6">
              <div className="text-center flex justify-between">
                <h6 className="text-blueGray-700 text-xl font-bold">Rooms list</h6>
              </div>
            </div>
            <div className="flex h-full flex-wrap px-4 py-2">
              {error ? <h2>{error}</h2> : <></>}
              {rooms?.map((room, index) => (
                <div className="lg:w-6/12 py-2 px-2" key={`room-${index}`}>
                  <div className="flex flex-col bg-white rounded-lg border shadow-md md:flex-row dark:border-gray-700 dark:bg-gray-800" style={{ height: "18.5rem" }}>
                    <img
                      className="object-cover rounded-t-lg md:rounded-none md:rounded-l-lg"
                      src={room.medias?.[0] ? `http://localhost:5000/medias/rooms/${room.medias[0].media_url}` : "https://cdn-icons-png.flaticon.com/512/3565/3565390.png"}
                      alt="room label"
                      width={300}
                      style={{ height: "18.5rem" }}
                    />
                    <div className="flex flex-col p-4 leading-normal" style={{ width: "27rem" }}>
                      <div className="flex flex-row justify-between">
                        <h5 className="mb-2 text-xl font-bold tracking-tight text-gray-900 dark:text-white">Room: {room.name}</h5>

                        <button onClick={() => showUpdateRoom(room)}>
                          <span>Edit</span>
                        </button>
                      </div>

                      {room.beds ? (
                        <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
                          <b>Number of beds:</b> {room.beds}
                        </p>
                      ) : null}
                      {room.area ? (
                        <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
                          <b>Area:</b> {room.area}
                        </p>
                      ) : null}
                      {room.price ? (
                        <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
                          <b>Price:</b> {room.price}
                        </p>
                      ) : null}
                      <p className="mb-3 font-normal text-gray-700 dark:text-gray-400 flex-wrap">{room?.description?.substring(0, 300)}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {/* Modal Update Romm */}
      {selectedRoom ? <RoomModalUpdate updateRoomInList={updateRoomInList} initValue={selectedRoom} hideModal={hideUpdateModal} /> : null}
    </>
  );
}
