import React, {useEffect, useState} from "react";
import { useForm } from "react-hook-form";
import {updateAmenties} from "../../../services/hotels";
import amenties from "../../../utils/amenties";

const defaultValue = amenties.reduce((prevVal, curVal) => ({...prevVal, [curVal]: false}), {});

export default function HotelAmenties({hotelInfo}) {
  const { handleSubmit } = useForm();
  const [selectedAmenties, setSelectedAmenties] = useState(defaultValue);

  useEffect(() => {
    if (hotelInfo?.amenties?.length) {
      const values = hotelInfo.amenties.reduce((prevVal, curVal) => ({...prevVal, [curVal.amentie]: true}), defaultValue);
      setSelectedAmenties(values);
    }
  }, [hotelInfo]);

  const handleFormSubmit = async () => {
    try {
      if (hotelInfo?.id) {
        let dataToSend = [];
        for (const [key, value] of Object.entries(selectedAmenties)) {
          if(value) dataToSend.push(key);
        }
        console.log(dataToSend);
        if (dataToSend.length) {
          const response = await updateAmenties({hotelId: hotelInfo.id, amenties: dataToSend});
          alert('Informations successfully updated');
          console.log(response);
        } else alert('Amenties required');
        
      } else alert('You must first fill in the information of your hotel');
    } catch (error) {
      console.log(error);
      alert('Internal server error');
    }
  };

  const handeChangeValue = (event) => {
    const amentie = event.currentTarget.dataset.amentie;
    setSelectedAmenties({...selectedAmenties, [amentie]: event.target.checked});
  };

  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <div className="rounded-t bg-white mb-0 px-6 py-6">
            <div className="text-center flex justify-between">
              <h6 className="text-blueGray-700 text-xl font-bold">Hotel Amenties</h6>
              <button
                className="bg-purple-500 text-white active:bg-purple-500 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                type="submit"
              >
                Save
              </button>
            </div>
          </div>
          <div className="flex-auto px-4 lg:px-10 py-10">
            <div className="w-full px-4">
              <div className="flex relative w-full mb-3 flex-wrap">
                {amenties.map(amentie => (
                  <div className="flex items-center mb-2 mt-2 mr-4" key={amentie}>
                    <input
                      id={amentie}
                      type="checkbox"
                      onChange={handeChangeValue}
                      data-amentie={amentie}
                      checked={!!selectedAmenties[amentie]}
                      className="w-4 mr-2 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500"
                    />
                    <label
                      htmlFor={amentie}
                      className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      {amentie}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
