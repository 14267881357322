import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

const UserForm = ({ onSubmit, initValue }) => {
  const { register, handleSubmit, reset } = useForm();
  const [role, setRole] = useState("ADMIN");

  useEffect(() => {
    reset(initValue);
  }, [reset, initValue]);

  const handleFormSubmit = async (formData) => {
    await onSubmit(formData);
    setRole("ADMIN");
    reset();
  };

  return (
    <form className="space-y-6" onSubmit={handleSubmit(handleFormSubmit)}>
      <div className="flex-1 px-2">
        <label htmlFor="role" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
          User role
        </label>
        <select
          type="text"
          name="role"
          {...register("role")}
          onChange={(e) => setRole(e.target.value)}
          value={role}
          id="role"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
          required
        >
          <option value="ADMIN">ADMIN</option>
          <option value="HOTEL">HOTEL</option>
          <option value="PMS">PMS</option>
        </select>
      </div>
      {(role === "ADMIN" || role === "PMS") && (
        <>
          <div className="flex flex-row">
            <div className="flex-1">
              <label htmlFor="firstName" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                First name
              </label>
              <input
                type="text"
                name="firstName"
                {...register("firstName")}
                id="firstName"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                placeholder="Jhone"
                required
              />
            </div>
            <div className="flex-1 px-2">
              <label htmlFor="lastName" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                Last name
              </label>
              <input
                type="text"
                name="lastName"
                {...register("lastName")}
                id="lastName"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                placeholder="Dhoe"
                required
              />
            </div>
          </div>
          <div className="flex flex-row">
            <div className="flex-1">
              <label htmlFor="userName" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                Username
              </label>
              <input
                type="text"
                name="userName"
                {...register("userName")}
                id="userName"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                placeholder="jhoneDone"
                required
              />
            </div>
          </div>
          <div>
            <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Email
            </label>
            <input
              type="email"
              name="email"
              {...register("email")}
              id="email"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
              placeholder="jhonedoe@example.com"
              required
            />
          </div>
        </>
      )}
      {role === "HOTEL" && (
        <>
          <div className="flex flex-row">
            <div className="flex-1">
              <label htmlFor="firstName" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                First name
              </label>
              <input
                type="text"
                name="firstName"
                {...register("firstName")}
                id="firstName"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                placeholder="Jhone"
                required
              />
            </div>
            <div className="flex-1 px-2">
              <label htmlFor="lastName" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                Last name
              </label>
              <input
                type="text"
                name="lastName"
                {...register("lastName")}
                id="lastName"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                placeholder="Dhoe"
                required
              />
            </div>
          </div>
          <div className="flex flex-row">
            <div className="flex-1">
              <label htmlFor="userName" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                Username
              </label>
              <input
                type="text"
                name="userName"
                {...register("userName")}
                id="userName"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                placeholder="jhoneDone"
                required
              />
            </div>
          </div>
          <div>
            <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Email
            </label>
            <input
              type="email"
              name="email"
              {...register("email")}
              id="email"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
              placeholder="jhonedoe@example.com"
              required
            />
          </div>
          <div>
            <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Hotel Name
            </label>
            <input
              type="name"
              name="name"
              {...register("name")}
              id="name"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
              placeholder="Hotel BELLE VIE"
              required
            />
          </div>
          <div>
            <label htmlFor="cityCode" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              City Code
            </label>
            <input
              type="cityCode"
              name="cityCode"
              {...register("cityCode")}
              id="cityCode"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
              placeholder="City Code"
              required
            />
          </div>
          <div>
            <label htmlFor="phone" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Phone
            </label>
            <input
              type="phone"
              name="phone"
              {...register("phone")}
              id="phone"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
              placeholder="+33 ..."
              required
            />
          </div>
          <div>
            <label htmlFor="address" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Address
            </label>
            <input
              type="address"
              name="address"
              {...register("address")}
              id="address"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
              placeholder="Rue ..."
              required
            />
          </div>
        </>
      )}
      <button
        type="submit"
        className="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
      >
        Save
      </button>
    </form>
  );
};

export default UserForm;
