import React from "react";
import { Redirect, Route } from "react-router-dom";

const PublicRoute = ({ component: Component, ...restOfProps }) => {
  const isAuthenticated = !!localStorage.getItem("token");

  return (
    <Route
      {...restOfProps}
      render={(props) =>
        isAuthenticated ? <Redirect to="/admin/dashboard" /> : <Component {...props} />
      }
    />
  );
}

export default PublicRoute;