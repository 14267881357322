import { authenticatedApi } from "./base";

export const users = async () => {
  try {
    const auth = await authenticatedApi();
    const { data: response } = await auth.get(`${process.env.REACT_APP_SERVER_URI}/users`);
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const user = async (uid) => {
  try {
    const auth = await authenticatedApi();
    const { data: response } = await auth.get(`${process.env.REACT_APP_SERVER_URI}/users/${uid}`);
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const addUser = async (params) => {
  try {
    const auth = await authenticatedApi();
    const { data: response } = await auth.post(`${process.env.REACT_APP_SERVER_URI}/users/admin-create`, params);
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const updateUser = async (id, params) => {
  const auth = await authenticatedApi();
  const { data: response } = await auth.patch(`${process.env.REACT_APP_SERVER_URI}/users/${id}`, params);
  return response;
};
