import axios from "axios";
import { supabase } from "../config/supabase";

// Super simple function to build the query string and apply some formatting.
export const url = (route) => {
  const formattedRoute = (route ?? "").replace(/^\//, "");
  // return API_BASE_URL + formattedRoute;
  return process.env.REACT_APP_SERVER_URI + formattedRoute;
};

export const api = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URI,
  headers: {
    "Content-Type": "application/json",
  },
});

export const authenticatedApi = async () => {
  const {
    data: { session },
    error,
  } = await supabase.auth.getSession();
  return axios.create({
    baseURL: process.env.REACT_APP_SERVER_URI,
    headers: {
      Authorization: `Bearer ${session.access_token}`,
      refresh: session.refresh_token,
      "Content-Type": "application/json",
    },
  });
};
