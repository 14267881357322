import React, { memo } from "react";
import { deleteHotel } from "../../../services/hotels";

const HotelModalDelete = memo(({ hotelId, setHotelId, deletehotelFromList, hideConfirmDelete }) => {
  const handleDeleteHotel = async () => {
    try {
      await deleteHotel(hotelId);
      deletehotelFromList(hotelId);
      hideConfirmDelete();
      setHotelId('');
    } catch (error) {
      alert('Error occured ', error);
    }
  };

  return (
    <div
      id="confirm-delete-modal"
      tabIndex="-1"
      aria-hidden="true"
      className="hidden overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full"
    >
      <div className="relative p-4 w-1/2 max-w-md h-full">
        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
          <div className="py-2 px-6 lg:px-8">
            <div className="flex flex-row items-center">
              <h3 className="text-xl font-medium text-gray-900 dark:text-white">Confirm hotel deletion</h3>
              <button
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
                onClick={hideConfirmDelete}
              >
                <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  >
                  </path>
                </svg>
              </button>
            </div>
          </div>
          <div className="p-6">
            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
              Do you want to delete this hotel.
            </p>
          </div>
          <div className="flex items-center justify-end p-6 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600">
            <button
              onClick={hideConfirmDelete}
              type="button"
              className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
            >
              Decline
            </button>
            <button
              onClick={handleDeleteHotel}
              type="button"
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              Accept
            </button>
          </div>
        </div>
      </div>
    </div>
  );
});

export default HotelModalDelete;