import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// components
import AdminNavbar from "components/Navbars/AdminNavbar";
import HeaderStats from "components/Headers/HeaderStats";
import FooterAdmin from "components/Footers/FooterAdmin";
// views
import Dashboard from "views/admin/Dashboard";
import Users from "views/admin/Users";
import Hotels from "views/admin/Hotels/Hotels";
import Pms from "views/admin/Pms";
import Reservations from "views/admin/Reservations";

export default function Admin() {
  return (
    <>
      <div className="relative bg-blueGray-100">
        <AdminNavbar />
        {/* Header */}
        <HeaderStats showState={window.location.href.indexOf("/admin/dashboard") !== -1} />
        <div className="px-4 md:px-10 mx-auto w-full -m-24 h-screen">
          <Switch>
            <Route path="/admin/dashboard" exact component={Dashboard} />
            <Route path="/admin/users" exact component={Users} />
            <Route path="/admin/pms" exact component={Pms} />
            <Route path="/admin/hotels" exact component={Hotels} />
            <Route path="/admin/reservations" exact component={Reservations} />
            <Redirect from="/admin" to="/admin/dashboard" />
          </Switch>
        </div>
        <FooterAdmin />
      </div>
    </>
  );
}
