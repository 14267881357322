import React from "react";
import { useAuth } from "../../../context/AuthContext";

import UserInfo from "./UserInfo";
import BankInfo from "./BankInfo";
import UserPassword from "./UserPassword";

export default function Profile() {
  const { user, pms } = useAuth();
  return (
    <>
      <div className="flex flex-wrap">
        <div className="lg:w-12/12 w-full px-4">
          <UserInfo userInfo={user} pmsInfo={pms} />
        </div>
        <div className="w-full lg:w-12/12 px-4">
          <UserPassword userInfo={user} />
        </div>
        <div className="w-full lg:w-12/12 px-4">{pms?.id ? <BankInfo pmsInfo={pms} userInfo={user} /> : null}</div>
      </div>
    </>
  );
}
