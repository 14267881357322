import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useAuth } from "../../../context/AuthContext";
import amenties from "../../../utils/amenties";
import RoomMedia from "../profile/RoomMedia";

const defaultValue = amenties.reduce((prevVal, curVal) => ({ ...prevVal, [curVal]: false }), {});

const PmsForm = ({ onSubmit, initValue }) => {
  console.log(initValue)
  const [selectedAmenties, setSelectedAmenties] = useState(defaultValue);
  const { user } = useAuth();

  const { id, name, area, beds, description, amenties: amentiesData } = initValue;

  const { register, handleSubmit, reset } = useForm();

  useEffect(() => {
    if (amentiesData?.length) {
      const values = amentiesData.reduce((prevVal, curVal) => ({ ...prevVal, [curVal.amentie]: true }), defaultValue);
      setSelectedAmenties(values);
    }
  }, [amentiesData]);

  useEffect(() => {
    if (id) {
      reset({
        name,
        area,
        beds,
        description,
      });
    }
  }, [id, name, area, beds, description, reset]);

  const handeChangeValue = (event) => {
    const amentie = event.currentTarget.dataset.amentie;
    setSelectedAmenties({ ...selectedAmenties, [amentie]: event.target.checked });
  };

  const handleFormSubmit = async ({ medias, ...formData }) => {
    let amentiesToSend = [];

    // amenties
    for (const [key, value] of Object.entries(selectedAmenties)) {
      if (value)
        amentiesToSend.push({
          amentie: key,
          room: { id },
        });
    }

    await onSubmit({
      ...formData,
      amenties: amentiesToSend,
      hotel: { id: user.hotel.id },
    });
    reset();
  };

  return (
    <div>
      <form className="space-y-6" onSubmit={handleSubmit(handleFormSubmit)}>
        <div className="flex flex-row">
          <div className="flex-1">
            <label htmlFor="roomName" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Room name
            </label>
            <h1>{initValue.name}</h1>
          </div>
          <div className="flex-1 px-2">
            <label htmlFor="roomArea" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Room area
            </label>
            <input
              type="number"
              name="roomArea"
              {...register("area")}
              id="roomArea"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
              placeholder="10 m2"
              required
            />
          </div>
          <div className="flex-1 px-2">
            <label htmlFor="beds" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Room beds
            </label>
            <h1>{initValue.beds}</h1>
          </div>
        </div>
        <div className="flex flex-row">
          <div className="flex relative w-full mb-3 flex-wrap">
            {amenties.map((amentie) => (
              <div className="flex items-center mb-2 mt-2 mr-4" key={amentie}>
                <input
                  id={amentie}
                  type="checkbox"
                  onChange={handeChangeValue}
                  data-amentie={amentie}
                  checked={!!selectedAmenties[amentie]}
                  className="w-4 mr-2 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500"
                />
                <label htmlFor={amentie} className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                  {amentie}
                </label>
              </div>
            ))}
          </div>
        </div>
        <div className="flex flex-row">
          <div className="flex-1">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300" htmlFor="room-description">
              Description
            </label>
            <textarea
              className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              defaultValue="is simply dummy text of the printing and typesetting industry."
              rows="4"
              name="description"
              id="room-description"
              {...register("description")}
            />
          </div>
        </div>

        <button
          type="submit"
          className="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
          Save
        </button>
      </form>
      <div className="w-full my-8 h-2 "></div>
      <RoomMedia roomData={initValue} />
    </div>
  );
};

export default PmsForm;
