import React, { useState, useEffect, useCallback } from "react";
import HotelsCardTable from "./HotelsCardTable";
import { hotels as getHotels } from "../../../services/hotels";
import ReactPaginate from "react-paginate";

export default function Hotels() {
  const [hotels, setHotels] = useState([]);
  const [pageOffset, setPageOffset] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [pageCount, setPagecount] = useState(0);
  useEffect(() => {
    getHotels(pageLimit, pageOffset)
      .then((data) => {
        setHotels(data.data);
        setPagecount(data.pagination);
      })
      .catch((err) => console.log("error ", err));
  }, [pageOffset, pageLimit]);

  const addhotelToList = useCallback(
    (hotel) => {
      const newList = [...hotels, hotel];
      setHotels(newList);
    },
    [hotels]
  );

  const updatehotelInList = useCallback(
    (updatedHotel) => {
      const newList = [...hotels];
      const hotelIndex = hotels.findIndex((hotel) => hotel.id === updatedHotel.id);
      newList[hotelIndex] = updatedHotel;
      setHotels(newList);
    },
    [hotels]
  );

  const deletehotelFromList = useCallback(
    (hotelId) => {
      const newList = hotels.filter((hotel) => hotel.id !== hotelId);
      setHotels(newList);
    },
    [hotels]
  );

  const handlePageClick = (e) => {
    setPageOffset(e.selected + 1);
  };

  return (
    <div className="flex flex-wrap mt-4">
      <div className="w-full mb-12 px-4">
        <HotelsCardTable hotels={hotels} addhotelToList={addhotelToList} updatehotelInList={updatehotelInList} deletehotelFromList={deletehotelFromList} />
        <div>
          <ReactPaginate
            breakLabel="..."
            nextLabel="next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="< previous"
            renderOnZeroPageCount={null}
            className="mx-auto flex justify-between w-full xl:w-1/2"
          />
        </div>
      </div>
    </div>
  );
}
