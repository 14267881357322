import React, { useState, useCallback } from "react";
import ReservationDropdown from "./ReservationDropdown";
import ReservationModalCreate from "./ReservationModalCreate";
import ReservationModalUpdate from "./ReservationModalUpdate";
import ReservationModalDelete from "./ReservationModalDelete";

const ReservationsCardTable = ({ reservations, addReservationToList, updateReservationInList, deleteReservationFromList }) => {
  const [reservationId, setReservationId] = useState('');
  const [selectedReservation, setSelectedReservation] = useState({});
  const modalEl = document.getElementById('create-reservation-modal');
  const modalUpdateEl = document.getElementById('update-reservation-modal');
  const modalConfirmDelete = document.getElementById('confirm-delete-modal');

  const showCreateModal = () => {
    const modal = new window.Modal(modalEl);
    modal.show();
  };

  const showUpdateModal = useCallback((reservation) => {
    setSelectedReservation(reservation);
    const modal = new window.Modal(modalUpdateEl);
    modal.show();
  }, [modalUpdateEl]);

  const showConfirmDelete = useCallback((reservationId) => {
    const modal = new window.Modal(modalConfirmDelete);
    modal.show();
    setReservationId(reservationId);
  }, [modalConfirmDelete]);

  const hideModal = useCallback(() => {
    const modal = new window.Modal(modalEl);
    modal.hide();
    const modalBackdrops = document.querySelectorAll('[modal-backdrop]');
    modalBackdrops.forEach(element => element.remove());
  }, [modalEl]);

  const hideConfirmDelete = useCallback(() => {
    const modal = new window.Modal(modalConfirmDelete);
    modal.hide();
    const modalBackdrops = document.querySelectorAll('[modal-backdrop]');
    modalBackdrops.forEach(element => element.remove());
  }, [modalConfirmDelete]);

  const hideUpdateModal = useCallback(() => {
    const modal = new window.Modal(modalUpdateEl);
    modal.hide();
    setSelectedReservation({});
    const modalBackdrops = document.querySelectorAll('[modal-backdrop]');
    modalBackdrops.forEach(element => element.remove());
  }, [modalUpdateEl]);

  return (
    <>
      {/* Modal Create Reservation */}
      <ReservationModalCreate
        addReservationToList={addReservationToList}
        hideModal={hideModal}
      />

      {/* Modal Update Reservation */}
      <ReservationModalUpdate
        updateReservationInList={updateReservationInList}
        initValue={selectedReservation}
        hideModal={hideUpdateModal}
      />

      {/* Modal Confirm Delete Reservation */}
      <ReservationModalDelete
        deleteReservationFromList={deleteReservationFromList}
        reservationId={reservationId}
        setReservationId={setReservationId}
        hideConfirmDelete={hideConfirmDelete}
      />

      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white"
      >
        <div className="rounded-t mb-0 px-4 py-3 border-0">
          <div className="flex flex-row flex-wrap items-center justify-between">
            <div className="relative px-4 max-w-full">
              <h3
                className="font-semibold text-lg text-blueGray-700"
              >
                Reservations Table
              </h3>
            </div>
            {/* <div className="relative px-4 max-w-full">
              <button
                onClick={showCreateModal}
                type="button"
                className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
              >
                Add Reservation
              </button>
            </div> */}
          </div>
        </div>
        <div className="block w-full overflow-x-auto">
          {/* Reservations table */}
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                <th
                  className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                >
                  Hotel ID
                </th>
                <th
                  className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                >
                  Hotel name
                </th>
                <th
                  className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                >
                  Offer id
                </th>
                <th
                  className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                >
                  Email
                </th>
                <th
                  className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                >
                  Amount
                </th>
                <th
                  className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                >
                  Status
                </th>
                <th
                  className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                >
                  Guests/Rooms
                </th>
                <th
                  className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                >
                  Checkin date
                </th>
                <th
                  className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                >
                  Checkout date
                </th>
                <th
                  className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                >
                  Reservation date
                </th>
                <th
                  className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                ></th>
              </tr>
            </thead>
            <tbody>
              {reservations.length ? reservations.map(reservation => (
                <tr key={reservation.id}>
                  <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                    <span className="ml-3 font-bold text-blueGray-600">
                      {reservation.hotel.hotelId}
                    </span>
                  </th>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {reservation.hotel.name}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {reservation.offerId}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {reservation.user.email}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 uppercase">
                    {reservation.amount} {reservation.currency}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 uppercase">
                    {reservation.status}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 uppercase">
                    <b>Guests:</b> {reservation.guests} <b>Rooms:</b> {reservation.rooms}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {new Date(reservation.checkinDate).toLocaleString('fr-FR', {dateStyle: 'long'})}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {new Date(reservation.checkoutDate).toLocaleString('fr-FR', {dateStyle: 'long'})}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {new Date(reservation.created_at).toLocaleString('fr-FR', {dateStyle: 'long'})}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right">
                    <ReservationDropdown
                      selectedReservation={reservation}
                      handleUpdateReservation={showUpdateModal}
                      handleDeleteReservation={showConfirmDelete}
                    />
                  </td>
                </tr>
              )) : <></>}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default ReservationsCardTable;