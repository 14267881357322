import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { connectPms } from "../../../services/pms";

export default function PmsInfo({ hotelInfo, pmsList, setHotelInfo }) {
  const { register, handleSubmit, reset, watch } = useForm();
  useEffect(() => {
    if (hotelInfo.pms?.id) {
      reset({ ...hotelInfo, pmsId: `${hotelInfo.pms?.id}-${hotelInfo.pms?.pmsName}` });
    }
  }, [reset, hotelInfo]);

  const watchPms = watch("pmsId") || "";
  const handleFormSubmit = async (formData) => {
    try {
      if (!hotelInfo.id) {
        alert("You must first fill in the information of your hotel");
        return;
      }
      const { pmsId, pmsUsername, pmsPassword, pmsToken, pmsIp, accessToken, clientName } = formData;
      const response = await connectPms({
        hotelId: hotelInfo.id,
        pmsId: pmsId.split("-")[0],
        pmsUsername,
        pmsPassword,
        pmsIp,
        pmsToken: pmsToken || "",
        accessToken: accessToken || "",
        clientName: clientName || "",
      });
      if (response.error) alert("Invalid credentials");
      else {
        const { pmsId, pmsUsername, pmsPassword, pmsIp, pmsToken, accessToken, clientName } = response;
        setHotelInfo({ ...hotelInfo, pmsId, pmsUsername, pmsPassword, pmsToken, accessToken, clientName, pmsIp });
        alert("Informations successfully updated");
      }
    } catch (error) {
      console.log("this is pms error", error);
    }
  };
  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <div className="rounded-t bg-white mb-0 px-6 py-6">
            <div className="text-center flex justify-between">
              <h6 className="text-blueGray-700 text-xl font-bold">Connect Pms account</h6>
              <button
                className="bg-purple-500 text-white active:bg-purple-500 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                type="submit"
                disabled={!hotelInfo.id}
              >
                Save
              </button>
            </div>
          </div>
          <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">Connect pms account</h6>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-12/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                    Select Pms
                  </label>
                  <select
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    {...register("pmsId")}
                  >
                    {pmsList.map((pms) => (
                      <option value={`${pms.id}-${pms.pmsName}`} key={`pms-${pms.id}`}>
                        {pms.pmsName}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="flex flex-wrap">
              {watchPms.includes("Thais") ? (
                <>
                  <div className="w-full lg:w-6/12 px-4">
                    <div className="relative w-full mb-3">
                      <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                        Username
                      </label>
                      <input
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="lucky.jesse"
                        {...register("pmsUsername")}
                      />
                    </div>
                  </div>
                  <div className="w-full lg:w-6/12 px-4">
                    <div className="relative w-full mb-3">
                      <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                        Password
                      </label>
                      <input
                        type="password"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="******************"
                        {...register("pmsPassword")}
                      />
                    </div>
                  </div>
                </>
              ) : null}
              {watchPms.includes("Ibelsa") ? (
                <>
                  <div className="w-full lg:w-12/12 px-4">
                    <div className="relative w-full mb-3">
                      <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                        Pms Token
                      </label>
                      <input
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Token"
                        {...register("pmsToken")}
                      />
                    </div>
                  </div>
                </>
              ) : null}
              {watchPms.includes("Mews") ? (
                <>
                  <div className="w-full lg:w-6/12 px-4">
                    <div className="relative w-full mb-3">
                      <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">Token</label>
                      <input
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Token"
                        {...register("pmsToken")}
                      />
                    </div>
                  </div>
                  <div className="w-full lg:w-6/12 px-4">
                    <div className="relative w-full mb-3">
                      <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">Acess Token</label>
                      <input
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Acess Token"
                        {...register("accessToken")}
                      />
                    </div>
                  </div>
                  <div className="w-full lg:w-6/12 px-4">
                    <div className="relative w-full mb-3">
                      <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">Client Name</label>
                      <input
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Client Name"
                        {...register("clientName")}
                      />
                    </div>
                  </div>
                </>
              ) : null}
              {watchPms.includes("Chloe") ? (
                <>
                  <div className="w-full lg:w-6/12 px-4">
                    <div className="relative w-full mb-3">
                      <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">Token</label>
                      <input
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Token"
                        {...register("pmsToken")}
                      />
                    </div>
                    <div className="relative w-full mb-3">
                      <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">Server IP</label>
                      <input
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="http://123.321.123.321:12345"
                        {...register("pmsIp")}
                      />
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
