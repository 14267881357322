import React, { useState, useEffect, useCallback } from "react";
import HotelsCardTable from "./HotelsCardTable";
import { hotelsPMS as getHotels } from "../../../services/hotels";

export default function Hotels() {
  const [hotels, setHotels] = useState([]);

  useEffect(() => {
    getHotels("28")
    .then(data => setHotels(data))
    .catch(err => console.log('error ', err));
  }, []);

  const addhotelToList = useCallback((hotel) => {
    const newList = [...hotels, hotel];
    setHotels(newList);
  }, [hotels]);

  const updatehotelInList = useCallback((updatedHotel) => {
    const newList = [...hotels];
    const hotelIndex = hotels.findIndex(hotel => hotel.id === updatedHotel.id);
    newList[hotelIndex] = updatedHotel;
    setHotels(newList);
  }, [hotels]);

  const deletehotelFromList = useCallback((hotelId) => {
    const newList = hotels.filter(hotel => hotel.id !== hotelId);
    setHotels(newList);
  }, [hotels]);

  return (
    <div className="flex flex-wrap mt-4">
      <div className="w-full mb-12 px-4">
        <HotelsCardTable
          hotels={hotels}
          addhotelToList={addhotelToList}
          updatehotelInList={updatehotelInList}
          deletehotelFromList={deletehotelFromList}
        />
      </div>
    </div>
  );
}
