import { authenticatedApi } from "./base";

export const reservations = async () => {
  try {
    const authApi = await authenticatedApi();
    const { data } = await authApi.get(`${process.env.REACT_APP_SERVER_URI}/hotels/admin/reservations`);
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const reservationsByHotelId = async (id) => {
  try {
    const authApi = await authenticatedApi();
    const { data } = await authApi.get(`${process.env.REACT_APP_SERVER_URI}/hotels/${id}/reservations`);
    return data;
  } catch (error) {
    console.log(error);
  }
};
