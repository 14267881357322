import React, { useState, useEffect, useCallback } from "react";
import ReservationsCardTable from "./ReservationsCardTable";
import { reservations as getReservations } from "../../../services/reservations";

export default function Reservations() {
  const [reservations, setReservations] = useState([]);
  console.log('reservations ', reservations);
  useEffect(() => {
    getReservations()
    .then(data => setReservations(data))
    .catch(err => console.log('error ', err));
  }, []);

  const addReservationToList = useCallback((reservation) => {
    const newList = [...reservations, reservation];
    setReservations(newList);
  }, [reservations]);

  const updateReservationInList = useCallback((updatedReservation) => {
    const newList = [...reservations];
    const reservationIndex = reservations.findIndex(hotel => hotel.id === updatedReservation.id);
    newList[reservationIndex] = updatedReservation;
    setReservations(newList);
  }, [reservations]);

  const deleteReservationFromList = useCallback((reservationId) => {
    const newList = reservations.filter(reservation => reservation.id !== reservationId);
    setReservations(newList);
  }, [reservations]);

  return (
    <div className="flex flex-wrap mt-4">
      <div className="w-full mb-12 px-4">
        <ReservationsCardTable
          reservations={reservations}
          addReservationToList={addReservationToList}
          updateReservationInList={updateReservationInList}
          deleteReservationFromList={deleteReservationFromList}
        />
      </div>
    </div>
  );
}
