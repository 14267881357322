import React, { useEffect } from "react";
import { useForm } from "react-hook-form";

const HotelForm = ({ onSubmit, initValue }) => {
  const { register, handleSubmit, reset } = useForm();

  useEffect(() => {
    reset(initValue);
  }, [reset, initValue]);

  const handleFormSubmit = async (formData) => {
    await onSubmit(formData);
    reset();
  };

  return (
    <form className="space-y-6" onSubmit={handleSubmit(handleFormSubmit)}>
      <div className="flex flex-row">
        <div className="flex-1">
          <label htmlFor="hotelId" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Hotel ID</label>
          <input
            type="text"
            name="hotelId"
            {...register("hotelId")}
            id="hotelId"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
            placeholder="WILGBLON"
            required
          />
        </div>
        <div className="flex-1 px-2">
          <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Hotel name</label>
          <input
            type="text"
            name="name"
            {...register("name")}
            id="name"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
            placeholder="The Westin Long Beach"
            required
          />
        </div>
      </div>
      <div className="flex flex-row">
        <div className="flex-1">
          <label htmlFor="rating" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Hotel rating</label>
          <input
            type="number"
            min={0}
            max={5}
            name="rating"
            {...register("rating")}
            id="rating"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
            placeholder="2"
            required
          />
        </div>
        <div className="flex-1 px-2">
          <label htmlFor="cityCode" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">City Code</label>
          <input
            type="text"
            name="cityCode"
            {...register("cityCode")}
            id="cityCode"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
            placeholder="LGB"
            required
          />
        </div>
      </div>
      <div className="flex flex-row">
        <div className="flex-1">
          <label htmlFor="latitude" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Latitude</label>
          <input
            type="text"
            name="latitude"
            {...register("latitude")}
            id="latitude"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
            placeholder="33.76675"
            required
          />
        </div>
        <div className="flex-1 px-2">
          <label htmlFor="longitude" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Longitude</label>
          <input
            type="text"
            name="longitude"
            {...register("longitude")}
            id="longitude"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
            placeholder="-118.18851"
            required
          />
        </div>
      </div>
      <div className="flex flex-row">
        <div className="flex-1">
          <label htmlFor="phone" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Phone</label>
          <input
            type="text"
            name="phone"
            {...register("phone")}
            id="phone"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
            placeholder="+1 562 436-3000"
            required
          />
        </div>
        <div className="flex-1 px-2">
          <label htmlFor="fax" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Fax</label>
          <input
            type="text"
            name="fax"
            {...register("fax")}
            id="fax"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
            placeholder="+1 562 436-9176"
            required
          />
        </div>
      </div>
      <div>
        <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Email</label>
        <input
          type="email"
          name="email"
          {...register("email")}
          id="email"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
          placeholder="westinlongbeach@westinlb.com"
          required
        />
      </div>
      <div>
        <label htmlFor="address" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Address</label>
        <input
          type="text"
          name="address"
          {...register("address")}
          id="address"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
          placeholder="333 EAST OCEAN BLVD"
          required
        />
      </div>
      <button
        type="submit"
        className="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
      >
        Save
      </button>
    </form>
  );
};

export default HotelForm;