import React, {useCallback, useState} from "react";
import PmsDropdown from "./PmsDropdown";
import PmsModalCreate from "./PmsModalCreate";
import PmsModalUpdate from "./PmsModalUpdate";

import PmsModalDelete from "./PmsModalDelete";

const choice = {
  REGISTER_WITH_USER_PASSWORD: 'Connect with username and password',
  REGISTER_WITH_HOTEL_ID: 'Connect with hotel ID',
  REGISTER_WITH_TOKEN: 'Connect with Api Token',
};

export default function PmsCardTable({pmsList, addPmsToList, updatePmsInList, deletePmsFromList}) {
  const [pmsId, setPmsId] = useState(0);
  const [selectedPms, setSelectedPms] = useState({});

  const modalEl = document.getElementById('create-pms-modal');
  const modalUpdateEl = document.getElementById('update-pms-modal');
  const modalConfirmDelete = document.getElementById('confirm-delete-modal');

  const showCreateModal = () => {
    const modal = new window.Modal(modalEl);
    modal.show();
  };

  const hideModal = useCallback(() => {
    const modal = new window.Modal(modalEl);
    modal.hide();
    const modalBackdrops = document.querySelectorAll('[modal-backdrop]');
    modalBackdrops.forEach(element => element.remove());
  }, [modalEl]);

  const showUpdatePms = useCallback((pms) => {
    setSelectedPms(pms);
    const modal = new window.Modal(modalUpdateEl);
    modal.show();
  }, [modalUpdateEl]);

  const hideUpdateModal = useCallback(() => {
    const modal = new window.Modal(modalUpdateEl);
    modal.hide();
    setSelectedPms({});
    const modalBackdrops = document.querySelectorAll('[modal-backdrop]');
    modalBackdrops.forEach(element => element.remove());
  }, [modalUpdateEl]);

  const showConfirmDelete = useCallback((pmsId) => {
    const modal = new window.Modal(modalConfirmDelete);
    modal.show();
    setPmsId(pmsId);
  }, [modalConfirmDelete]);

  const hideConfirmDelete = useCallback(() => {
    const modal = new window.Modal(modalConfirmDelete);
    modal.hide();
    const modalBackdrops = document.querySelectorAll('[modal-backdrop]');
    modalBackdrops.forEach(element => element.remove());
  }, [modalConfirmDelete]);

  return (
    <>
      {/* Modal Create Pms */}
      <PmsModalCreate
        addPmsToList={addPmsToList}
        hideModal={hideModal}
      />

      {/* Modal Update Pms */}
      <PmsModalUpdate
        updatePmsInList={updatePmsInList}
        initValue={selectedPms}
        hideModal={hideUpdateModal}
      />

      {/* Modal Confirm Delete Pms */}
      <PmsModalDelete
        deletePmsFromList={deletePmsFromList}
        pmsId={pmsId}
        setPmsId={setPmsId}
        hideConfirmDelete={hideConfirmDelete}
      />
      <div
        className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white"
      >
        <div className="rounded-t mb-0 px-4 py-3 border-0">
          <div className="flex flex-row flex-wrap items-center justify-between">
            <div className="relative px-4 max-w-full">
              <h3
                className="font-semibold text-lg text-blueGray-700"
              >
                Pms Table
              </h3>
            </div>
            <div className="relative px-4 max-w-full">
              <button
                onClick={showCreateModal}
                type="button"
                className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
              >
                Add pms
              </button>
            </div>
          </div>
        </div>

        <div className="block w-full overflow-x-auto">
          {/* Pms table */}
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                <th
                  className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                >
                  Pms Name
                </th>
                <th
                  className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                >
                  Pms connect type
                </th>
                <th
                  className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                >
                  Pms api url
                </th>
                <th
                  className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                ></th>
              </tr>
            </thead>
            <tbody>
              {pmsList.length ? pmsList.map(pms => (
                <tr key={pms.id}>
                  <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                    <span className="ml-3 font-bold text-blueGray-600">
                      {pms.pmsName}
                    </span>
                  </th>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {choice[pms.regiterCondition]}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {pms.pmsApiUrl}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right">
                    <PmsDropdown
                      selectedPms={pms}
                      handleUpdateHotel={showUpdatePms}
                      handleDeletePms={showConfirmDelete}
                    />
                  </td>
                </tr>
              )) : <></>}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
