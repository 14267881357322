import React, { useState, useEffect, useCallback } from "react";
import { useAuth } from "../../../context/AuthContext";
import ReservationsCardTable from "./ReservationsCardTable";
import { reservationsByHotelId as getReservations } from "../../../services/reservations";
import { getHotel } from "../../../services/hotels";

export default function Reservations() {
  const [reservations, setReservations] = useState([]);
  const { user } = useAuth();

  const fetchReservations = async () => {
    try {
      const hotel = await getHotel(user.id);
      if (hotel.id) {
        const reservations = await getReservations(hotel.id);
        setReservations(reservations);
      }
    } catch (error) {
      console.log(error);
      alert("internal server error");
    }
  };

  useEffect(() => {
    if (user?.id) fetchReservations();
  }, [user?.id]);

  const addReservationToList = useCallback(
    (reservation) => {
      const newList = [...reservations, reservation];
      setReservations(newList);
    },
    [reservations]
  );

  const updateReservationInList = useCallback(
    (updatedReservation) => {
      const newList = [...reservations];
      const reservationIndex = reservations.findIndex((hotel) => hotel.id === updatedReservation.id);
      newList[reservationIndex] = updatedReservation;
      setReservations(newList);
    },
    [reservations]
  );

  const deleteReservationFromList = useCallback(
    (reservationId) => {
      const newList = reservations.filter((reservation) => reservation.id !== reservationId);
      setReservations(newList);
    },
    [reservations]
  );

  return (
    <div className="flex flex-wrap mt-4">
      <div className="w-full mb-12 px-4">
        <ReservationsCardTable
          reservations={reservations}
          addReservationToList={addReservationToList}
          updateReservationInList={updateReservationInList}
          deleteReservationFromList={deleteReservationFromList}
        />
      </div>
    </div>
  );
}
