import React, { useEffect, useState } from "react";
import { useAuth } from "../../../context/AuthContext";
import { user as getUser } from "../../../services/users";
import { getHotel } from "../../../services/hotels";
import { allPms as getPms } from "../../../services/pms";
import UserInfo from "./UserInfo";
import HotelInfo from "./HotelInfo";
import BankInfo from "./BankInfo";
import PmsInfo from "./PmsInfo";
import HotelMedia from "./HotelMedia";
import HotelReviews from "./HotelReviews";
import HotelAmenties from "./HotelAmenties";
import UserPassword from "./UserPassword";

export default function Profile() {
  const { user, hotel, setHotel } = useAuth();
  const [pmsList, setPmsList] = useState([]);
  useEffect(() => {
    if (user?.uid) {
      getPms()
        .then((data) => setPmsList(data))
        .catch((err) => console.log("error ", err));
    }
  }, [user?.uid, user?.id]);

  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full lg:w-12/12 px-4">
          <UserInfo userInfo={user} />
        </div>
        <div className="w-full lg:w-12/12 px-4">
          <UserPassword userInfo={user} />
        </div>
        <div className="w-full lg:w-12/12 px-4">
          <HotelInfo hotelInfo={hotel} setHotelInfo={setHotel} />
        </div>
        <div className="w-full lg:w-12/12 px-4">{hotel?.id ? <BankInfo hotelInfo={hotel} userInfo={user} /> : null}</div>
        <div className="w-full lg:w-12/12 px-4">{hotel?.id ? <PmsInfo hotelInfo={hotel} pmsList={pmsList} setHotelInfo={setHotel} /> : null}</div>
        <div className="w-full lg:w-12/12 px-4">{hotel?.id ? <HotelMedia hotelInfo={hotel} setHotelInfo={setHotel} /> : null}</div>
        <div className="w-full lg:w-12/12 px-4">{hotel?.id ? <HotelAmenties hotelInfo={hotel} setHotelInfo={setHotel} /> : null}</div>
        {/* <div className="w-full lg:w-12/12 px-4">
          <HotelReviews hotelInfo={hotelInfo} />
        </div> */}
      </div>
    </>
  );
}
