import React, { useState, useEffect, useCallback } from "react";
import PmsCardTable from "./PmsCardTable";
import { allPms as getPms } from "../../../services/pms";

export default function Pms() {
  const [pmsList, setPmsList] = useState([]);

  useEffect(() => {
    getPms()
    .then(data => setPmsList(data))
    .catch(err => console.log('error ', err));
  }, []);

  const addPmsToList = useCallback((pms) => {
    const newList = [...pmsList, pms];
    setPmsList(newList);
  }, [pmsList]);

  const updatePmsInList = useCallback((updatedPms) => {
    const newList = [...pmsList];
    const hotelIndex = pmsList.findIndex(pms => pms.id === updatedPms.id);
    newList[hotelIndex] = updatedPms;
    setPmsList(newList);
  }, [pmsList]);

  const deletePmsFromList = useCallback((pmsId) => {
    const newList = pmsList.filter(pms => pms.id !== pmsId);
    setPmsList(newList);
  }, [pmsList]);

  return (
    <div className="flex flex-wrap mt-4">
      <div className="w-full mb-12 px-4">
        <PmsCardTable
          pmsList={pmsList}
          addPmsToList={addPmsToList}
          updatePmsInList={updatePmsInList}
          deletePmsFromList={deletePmsFromList}
        />
      </div>
    </div>
  );
}
