const amenties = [
  'Parking',
  'Free Wifi',
  'Room Service',
  '24-Hour Guest Reception',
  'Complimentary Toiletries',
  'Healthy Breakfast',
  'Ample Wall Outlets',
  'Hair Styling Tools',
  'Flexible Checkout',
  'Pool',
  'Mini-fridge',
  'Complimentary Electronics Chargers',
  'Clothing Iron',
  'Business Facilities',
  'Transportation Information',
  'Free Breakfast',
  'Laundry Services',
  'Spa & Wellness Amenities',
  'Exercise Facilities and Accessories',
  'Daily Newspaper',
  'Entertainment',
  'Complimentary Luggage storage',
  'Cribs & Cots for Children',
  'Custom Offers',
  'Curated Experiences',
  'Fancy Bathrobes',
  'Kid-friendly Rooms and Products',
  'Premium Bedding',
  'Stain Remover Wipes',
  'Pet-friendly Rooms',
  'Champagne Bar',
];

export default amenties;