import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyB_g-MMEGshVKnb_pieCmn9j99y651aFJo",
  authDomain: "keyu-app-aaf6c.firebaseapp.com",
  projectId: "keyu-app-aaf6c",
  storageBucket: "keyu-app-aaf6c.appspot.com",
  messagingSenderId: "802667943683",
  appId: "1:802667943683:web:0427807147e73e9364fb36",
  measurementId: "G-V8DC7B57Z2"
};

const firebase = initializeApp(firebaseConfig);

export default firebase;