import React, { useState, useCallback } from "react";
import HotelDropdown from "./HotelDropdown";
import HotelModalCreate from "./HotelModalCreate";
import HotelModalUpdate from "./HotelModalUpdate";
import HotelModalDelete from "./HotelModalDelete";

const HotelsCardTable = ({ hotels, addhotelToList, updatehotelInList, deletehotelFromList }) => {
  const [hotelId, setHotelId] = useState('');
  const [selectedHotel, setSelectedHotel] = useState({});
  const modalEl = document.getElementById('create-hotel-modal');
  const modalUpdateEl = document.getElementById('update-hotel-modal');
  const modalConfirmDelete = document.getElementById('confirm-delete-modal');

  const showCreateModal = () => {
    const modal = new window.Modal(modalEl);
    modal.show();
  };

  const showUpdateModal = useCallback((hotel) => {
    setSelectedHotel(hotel);
    const modal = new window.Modal(modalUpdateEl);
    modal.show();
  }, [modalUpdateEl]);

  const showConfirmDelete = useCallback((hotelId) => {
    const modal = new window.Modal(modalConfirmDelete);
    modal.show();
    setHotelId(hotelId);
  }, [modalConfirmDelete]);

  const hideModal = useCallback(() => {
    const modal = new window.Modal(modalEl);
    modal.hide();
    const modalBackdrops = document.querySelectorAll('[modal-backdrop]');
    modalBackdrops.forEach(element => element.remove());
  }, [modalEl]);

  const hideConfirmDelete = useCallback(() => {
    const modal = new window.Modal(modalConfirmDelete);
    modal.hide();
    const modalBackdrops = document.querySelectorAll('[modal-backdrop]');
    modalBackdrops.forEach(element => element.remove());
  }, [modalConfirmDelete]);

  const hideUpdateModal = useCallback(() => {
    const modal = new window.Modal(modalUpdateEl);
    modal.hide();
    setSelectedHotel({});
    const modalBackdrops = document.querySelectorAll('[modal-backdrop]');
    modalBackdrops.forEach(element => element.remove());
  }, [modalUpdateEl]);

  return (
    <>
      {/* Modal Create Hotel */}
      <HotelModalCreate
        addhotelToList={addhotelToList}
        hideModal={hideModal}
      />

      {/* Modal Update Hotel */}
      <HotelModalUpdate
        updatehotelInList={updatehotelInList}
        initValue={selectedHotel}
        hideModal={hideUpdateModal}
      />

      {/* Modal Confirm Delete Hotel */}
      <HotelModalDelete
        deletehotelFromList={deletehotelFromList}
        hotelId={hotelId}
        setHotelId={setHotelId}
        hideConfirmDelete={hideConfirmDelete}
      />

      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white"
      >
        <div className="rounded-t mb-0 px-4 py-3 border-0">
          <div className="flex flex-row flex-wrap items-center justify-between">
            <div className="relative px-4 max-w-full">
              <h3
                className="font-semibold text-lg text-blueGray-700"
              >
                Hotels Table {hotels.length}
              </h3>
            </div>
            {/* <div className="relative px-4 max-w-full">
              <button
                onClick={showCreateModal}
                type="button"
                className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
              >
                Add Hotel
              </button>
            </div> */}
          </div>
        </div>
        <div className="block w-full overflow-x-auto">
          {/* Hotels table */}
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                <th
                  className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                >
                  Hotel ID
                </th>
                <th
                  className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                >
                  Name
                </th>
                <th
                  className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                >
                  Rating
                </th>
                <th
                  className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                >
                  CityCode
                </th>
                <th
                  className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                >
                  Lat/Long
                </th>
                <th
                  className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                >
                  Phone
                </th>
                <th
                  className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                >
                  Fax
                </th>
                <th
                  className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                >
                  Email
                </th>
                <th
                  className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                >
                  Address
                </th>
                <th
                  className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                ></th>
              </tr>
            </thead>
            <tbody>
              {hotels.length ? hotels.map(hotel => (
                <tr key={hotel.hotelId}>
                  <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                    <span className="ml-3 font-bold text-blueGray-600">
                      {hotel.hotelId}
                    </span>
                  </th>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {hotel.name}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {hotel.rating}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {hotel.cityCode}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {hotel.latitude}/{hotel.longitude}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {hotel.phone}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {hotel.fax}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {hotel.email}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {hotel.address}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right">
                    <HotelDropdown
                      selectedHotel={hotel}
                      handleUpdateHotel={showUpdateModal}
                      handleDeleteHotel={showConfirmDelete}
                    />
                  </td>
                </tr>
              )) : <></>}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default HotelsCardTable;