import React, { useState } from "react";
import { useAuth } from "../../context/AuthContext";
import { supabase } from "../../config/supabase";

export default function ChangePassword() {
  const [password, setPassword] = useState("");
  const { loading } = useAuth();

  const handleEmailChange = (event) => {
    setPassword(event.target.value);
  };

  const handleChangePassword = async (e) => {
    e.preventDefault();
    try {
      const { data, error } = await supabase.auth.updateUser({
        password,
      });
      if (error) throw new Error(error.message);
      if (data) window.location.reload();
    } catch (error) {
      console.log(error);
      alert(error);
    }
  };

  return (
    <div className="container mx-auto px-4 h-full">
      <div className="flex content-center items-center justify-center h-full">
        <div className="w-full lg:w-4/12 px-4">
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
            <div className="rounded-t mb-0 px-6 py-6" />
            <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
              <div className="w-full items-center my-4">
                <h1 className="font-bold">KEYU Backoffice</h1>
              </div>
              <form>
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="email">
                    Please insert your new password
                  </label>
                  <input
                    type="password"
                    name="password"
                    value={password}
                    onChange={handleEmailChange}
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Please enter your password..."
                  />
                </div>

                <div className="text-center mt-6 mb-6">
                  <button
                    className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                    disabled={loading}
                    onClick={handleChangePassword}
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
