import React, { useState } from "react";
import { deleteMedia, uploadMedia } from "../../../services/hotels";
import { supabase } from "../../../config/supabase";

export default function HotelMedia({ hotelInfo, setHotelInfo }) {

  const [files, setFiles] = useState([]);

  const handleFileChange = (e) => {
    const newFiles = Array.from(e.target.files);
    setFiles([...files, ...newFiles]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {

      const { data: { user }, error } = await supabase.auth.getUser();

      if (!user) throw new Error("Not Authenticated!")

      const promises = files.map(async (file) => {
        const path = user.id + "/" + file.name;

        const { error } = await supabase.storage.from('media').upload(path, file);
        if (error) {
          throw error;
        }

        const { data: urlData } = await supabase
          .storage
          .from('media')
          .getPublicUrl(path);
   
        const response = await uploadMedia({
          hotelId: hotelInfo.id,
          file: urlData.publicUrl,
        });
        return urlData.publicUrl;
      });
        
      const urls = await Promise.all(promises);

      window.location.reload();
    } catch (error) {
      console.error('Error uploading media:', error.message);
      alert('An error occurred while uploading media.');
    }
  };

  function getFolderAndFilename(foldername, imageUrl) {
    // Split the URL by "/"
    const parts = imageUrl.split("/");
    
    // Find the index of the last occurrence of "foldername"
    const folderIndex = parts.lastIndexOf(foldername);
    
    // Join the parts from "foldername" to the end to get the folder name and filename
    const folderAndFilename = parts.slice(folderIndex).join("/");
    
    // Return folder name and filename
    return folderAndFilename;
  }

  const removeMedia = async (event) => {

    try {
      const { mediaid, media_url } = event.currentTarget.dataset;
  
      const { data: { user } } = await supabase.auth.getUser();
  
      const file = getFolderAndFilename(user.id, media_url);
  
      const { error } = await supabase.storage.from('media').remove([file]);

      if (error) throw new Error(error.message);
      await deleteMedia(mediaid, media_url);
      let medias = [...hotelInfo.medias];
      medias = medias.filter((media) => media.id !== parseInt(mediaid, 10));
      setHotelInfo({ ...hotelInfo, medias });
    } catch (error) {
      console.log(error)
    }
  };

  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
        <form onSubmit={handleSubmit}>
          <div className="rounded-t bg-white mb-0 px-6 py-6">
            <div className="text-center flex justify-between">
              <h6 className="text-blueGray-700 text-xl font-bold">Hotel Media</h6>
              <button
                className="bg-purple-500 text-white active:bg-purple-500 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                type="submit"
              >
                Save
              </button>
            </div>
          </div>
          <div className="flex flex-wrap">
            {hotelInfo.medias?.length
              ? hotelInfo.medias.map((media, index) => (
                  <div key={`hote_media_${index}`} className="w-full lg:w-6/12 xl:w-3/12 px-2 py-2">
                    <div className="absolute ml-3 cursor-pointer" onClick={removeMedia} data-mediaid={media.id} data-media_url={media.media_url}>
                      <i className="fas fa-times mr-2 text-2xl text-red-500" />
                    </div>
                    <img src={media.media_url} className="shadow-lg object-fill w-full" style={{ height: 300, objectFit: 'contain' }} alt={`hote media ${index}`} />
                  </div>
                ))
              : null}
          </div>

          <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
            <div className="w-full lg:w-4/12 px-4">
              <div className="relative w-full mb-3">
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2 mt-3" htmlFor="grid-password">
                  Media
                </label>
                <input
                  type="file"
                  name="medias"
                  onChange={handleFileChange}
                  accept="image/*"
                  multiple
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
