import React, { useState } from "react";
import { connectStripe } from "../../../services/pms";

export default function BankInfo({ pmsInfo, userInfo }) {
  const [loading, setLoading] = useState(false);

  const handleConnectStripe = async () => {
    if (pmsInfo.id) {
      setLoading(true);
      const accountLink = await connectStripe({ pmsId: pmsInfo.id, name: pmsInfo.name, email: userInfo.email });
      setLoading(false);
      window.location.href = accountLink.url;
      window.open(accountLink.url);
    } else {
      alert("You must first fill in the information of your pms");
    }
  };

  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
        <div className="rounded-t bg-white mb-0 px-6 py-6">
          <div className="text-center flex justify-between">
            <h6 className="text-blueGray-700 text-xl font-bold">Bank account</h6>
          </div>
        </div>
        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
          <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">Bank account</h6>
          <div className="flex flex-wrap">
            <div className="w-full lg:w-6/12 px-4">
              <div className="relative w-full mb-3">
                <button
                  className={`${
                    loading || !pmsInfo.id || !userInfo.id || pmsInfo.stripeAccount ? "cursor-not-allowed" : ""
                  } bg-purple-500 text-white active:bg-purple-500 font-bold uppercase text-xl px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150`}
                  type="button"
                  // disabled={loading || !pmsInfo.id || !userInfo.id || pmsInfo.stripeAccount}
                  onClick={handleConnectStripe}
                >
                  {pmsInfo.stripeAccount ? "Stripe account is connected" : "Connect your stripe account"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
