import { supabase } from "../config/supabase";
import { authenticatedApi } from "./base";

const getAuth = async () => {
  const { data, error } = await supabase.auth.getSession();
  return data?.session?.access_token;
};

export const hotels = async (pageLimit, pageOffset) => {
  try {
    const authApi = await authenticatedApi();
    const { data } = await authApi.get(`${process.env.REACT_APP_SERVER_URI}/hotels/local-hotels?pageLimit=${pageLimit}&pageOffset=${pageOffset}`);
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const hotelsPMS = async (pmsID) => {
  try {
    const authApi = await authenticatedApi();
    const { data } = await authApi.get(`${process.env.REACT_APP_SERVER_URI}/hotels/local-hotels-by-pms/${pmsID}`);
    return data;
  } catch (error) {
    console.log(error);
  }
};

// export const hotel = async (userId) => {
//   const { data, error } = await supabase.auth.getSession();
//   const headers = {
//     "content-type": "application/json",
//     authorization: `Bearer ${"eyJhbGciOiJIUzI1NiIsImtpZCI6IkxpaElnc0tPZjZMcWpPZzMiLCJ0eXAiOiJKV1QifQ.eyJhdWQiOiJhdXRoZW50aWNhdGVkIiwiZXhwIjoxNzA4MDExMjQ3LCJpYXQiOjE3MDgwMDc2NDcsImlzcyI6Imh0dHBzOi8vdW1xc3l3dHJ5anRwbHd3Zm15ZW4uc3VwYWJhc2UuY28vYXV0aC92MSIsInN1YiI6ImM2M2Y3OWJhLThkNWEtNDcwYi05MjRjLWEwNjU2ZTRlNzlhZCIsImVtYWlsIjoic2ZvcmRldkBnbWFpbC5jb20iLCJwaG9uZSI6IiIsImFwcF9tZXRhZGF0YSI6eyJwcm92aWRlciI6ImVtYWlsIiwicHJvdmlkZXJzIjpbImVtYWlsIl19LCJ1c2VyX21ldGFkYXRhIjp7fSwicm9sZSI6ImF1dGhlbnRpY2F0ZWQiLCJhYWwiOiJhYWwxIiwiYW1yIjpbeyJtZXRob2QiOiJwYXNzd29yZCIsInRpbWVzdGFtcCI6MTcwODAwNzY0N31dLCJzZXNzaW9uX2lkIjoiYmE4YzQ4MTMtZWZmNS00YWMzLWIyNmMtZDQ5NWFkMDdlMjY4In0.UDNIFEHOyfKtWWLRqrEdq_VObSOwLTJnb0JYusEHBkA"}`,
//   };
//   console.log(headers);
//   const res = await fetch(`${process.env.REACT_APP_SERVER_URI}/hotels/hotel-by-user/${userId}`, {
//     method: "GET",
//     credentials: "include",
//     headers,
//   });
//   console.log(res);
//   const response = await res.json();
//   return response;
// };

export const getHotel = async (userId) => {
  try {
    const authApi = await authenticatedApi();
    const { data } = await authApi.get(`${process.env.REACT_APP_SERVER_URI}/hotels/hotel-by-user/${userId}`);
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const addHotel = async (data) => {
  try {
    const authApi = await authenticatedApi();
    const { data: response } = await authApi.get(`${process.env.REACT_APP_SERVER_URI}/hotels/`, data);
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const connectStripe = async (data) => {
  try {
    const authApi = await authenticatedApi();
    const { data: response } = await authApi.post(`${process.env.REACT_APP_SERVER_URI}/hotels/connect-stripe`, data);
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const updateHotel = async (data) => {
  const auth = await authenticatedApi();
  const { data: response } = await auth.patch(`${process.env.REACT_APP_SERVER_URI}/hotels/`, data);
  return response;
};

export const updateAmenties = async (data) => {
  try {
    const authApi = await authenticatedApi();
    const { data: response } = await authApi.post(`${process.env.REACT_APP_SERVER_URI}/hotels/amenties`, data);
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const addReview = async (data) => {
  try {
    const authApi = await authenticatedApi();
    const { data: response } = await authApi.post(`${process.env.REACT_APP_SERVER_URI}/hotels/reviews`, data);
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const uploadMedia = async (data) => {
  try {
    const authApi = await authenticatedApi();
    const { data: response } = await authApi.post(`${process.env.REACT_APP_SERVER_URI}/hotels/upload-media`, data);
    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const deleteMedia = async (mediaId) => {
  try {
    const authApi = await authenticatedApi();
    const { data: response } = await authApi.delete(`${process.env.REACT_APP_SERVER_URI}/hotels/medias/${mediaId}}`);
    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const deleteHotel = async (hotelId) => {
  const {
    data: { session },
    error,
  } = await supabase.auth.getSession();
  const res = await fetch(`${process.env.REACT_APP_SERVER_URI}/hotels/${hotelId}`, {
    method: "DELETE",
    credentials: "include",
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${session.access_token}`,
    },
  });

  const response = await res.json();
  return response;
};

export const deleteReview = async (reviewId) => {
  const {
    data: { session },
    error,
  } = await supabase.auth.getSession();
  const res = await fetch(`${process.env.REACT_APP_SERVER_URI}/hotels/reviews/${reviewId}`, {
    method: "DELETE",
    credentials: "include",
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${session.access_token}`,
    },
  });

  const response = await res.json();
  return response;
};

export const getReviewsQuestions = async (hotelId) => {
  const auth = await authenticatedApi();
  const { data: response } = await auth.get(`${process.env.REACT_APP_SERVER_URI}/hotels/reviews-questions/${hotelId}`);
  return response;
};

export const createReviewsQuestions = async (hotelId, data) => {
  const auth = await authenticatedApi();
  const body = {
    hotelId,
    ...data,
  };
  const { data: response } = await auth.post(`${process.env.REACT_APP_SERVER_URI}/hotels/reviews-questions`, body);
  return response;
};

export const updateReviewsQuestions = async (data) => {
  const auth = await authenticatedApi();
  const { data: response } = await auth.patch(`${process.env.REACT_APP_SERVER_URI}/hotels/reviews-questions`, data);
  return response;
};

export const deleteReviewsQuestions = async (reviewId, reviewQuestionsIds) => {
  const auth = await authenticatedApi();
  const body = {
    reviewId,
    reviewQuestionsIds,
  };
  const { data: response } = await auth.post(`${process.env.REACT_APP_SERVER_URI}/hotels/reviews-questions/delete`, body);
  return response;
};

export const getReviewsStatistics = async (id) => {
  try {
    const authApi = await authenticatedApi();
    const { data } = await authApi.get(`${process.env.REACT_APP_SERVER_URI}/hotels/reviews-dashboard/${id}`);
    return data;
  } catch (error) {
    console.log(error);
  }
};
