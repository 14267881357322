import { supabase } from "config/supabase";
import { authenticatedApi } from "./base";

export const rooms = async (userId) => {
  const {
    data: { session },
    error,
  } = await supabase.auth.getSession();
  const res = await fetch(`${process.env.REACT_APP_SERVER_URI}/hotels/rooms-by-user/${userId}`, {
    method: "GET",
    credentials: "include",
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${session.access_token}`,
    },
  });

  if (!res.ok) {
    console.log(`HTTP Error Response: ${res.status} ${res.statusText}`);
    throw new Error("rooms fetch error");
  }
  const response = await res.json();
  return response;
};

export const mergedRooms = async (hotelId) => {
  try {
    const authApi = await authenticatedApi();
    const { data } = await authApi.get(`${process.env.REACT_APP_SERVER_URI}/rooms/merged-rooms/${hotelId}`);
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const updateRoom = async (data) => {
  try {
    const authApi = await authenticatedApi();
    const { data: response } = await authApi.post(`${process.env.REACT_APP_SERVER_URI}/rooms`, data);
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const getRoomMedia = async ({roomPmsId, id}) => {
  try {
    const authApi = await authenticatedApi();
    const { data: response } = await authApi.get(`${process.env.REACT_APP_SERVER_URI}/rooms/media/${id}/${roomPmsId}`);
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const uploadMedia = async (data) => {
  try {
    const authApi = await authenticatedApi();
    const { data: response } = await authApi.post(`${process.env.REACT_APP_SERVER_URI}/rooms/upload-media`, data);
    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const deleteRoomMedia = async (id) => {
  try {
    console.log("this is id to delete", id);
    const authApi = await authenticatedApi();
    const { data: response } = await authApi.delete(`${process.env.REACT_APP_SERVER_URI}/rooms/upload-media/${id}`);
    return response;
  } catch (error) {
    console.log(error);
  }
};
