import { useState, useEffect, createContext, useContext } from "react";
import { useHistory } from "react-router-dom";
import "../config/firebase";
import { getUser, loginAsAdmin } from "../services/auth";
import { getHotel } from "../services/hotels";
import { supabase } from "../config/supabase";
import { getPmsUser } from "../services/pms";
// import { getHotel } from "../services/hotels";

const AuthContext = createContext({});

export const useAuth = () => {
  return useContext(AuthContext);
};

export function AuthProvider({ children }) {
  const history = useHistory();
  const [user, setUser] = useState(null);
  const [pmsUser, setPmsUser] = useState(null);
  const [admin, setAdmin] = useState(null);
  const [hotel, setHotel] = useState(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const { data: authListener } = supabase.auth.onAuthStateChange(async (event, session) => {
      if (session) {
        const fetch = async () => {
          localStorage.setItem("token", session.access_token);
          const userInfo = await getUser(session.user.id);
          setUser(userInfo);
        };
        fetch();
      } else {
        setUser(null);
        setLoading(false);
        localStorage.removeItem("token");
        history.push("/auth/login");
      }
    });
    return () => {
      authListener.subscription.unsubscribe();
    };
  }, []);
  useEffect(() => {
    if (user?.role === "ADMIN") {
      loginAsAdmin(user.email).then((res) => setAdmin(res));
    }
    if (user?.role === "PMS") {
      getPmsUser(user.id).then((res) => setPmsUser(res));
    }
    if (user?.role === "HOTEL") {
      getHotel(user.id).then((res) => setHotel(res));
    }
  }, [user?.id]);
  const logout = () => {
    localStorage.clear();
    supabase.auth.signOut().catch((error) => {
      console.log("error", error);
    });
  };

  const signIn = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData(e.target);
      let password = "";
      let email = "";

      formData.forEach((value, key) => {
        if (key === "email") email = value;
        if (key === "password") password = value;
      });
      setLoading(true);
      const { data, error } = await supabase.auth.signInWithPassword({
        email,
        password,
      });
      if (error) {
        alert(error.message);
        throw new Error(error.message);
      }
      const user = data.user;
      const userInfo = await getUser(user.id);
      console.log(userInfo);
      setUser(userInfo);
      // for each role call the get function
      if (userInfo.role === "ADMIN") {
        const response = await loginAsAdmin(email, data.session.access_token);
        setAdmin(response);
        history.push("/admin/dashboard");
      }
      // for each role call the get function
      if (userInfo.role === "PMS") {
        const response = await getPmsUser(userInfo.id);
        setPmsUser(response);
        history.push("/pms/profile");
      }
      // for each role call the get function
      if (userInfo.role === "HOTEL") {
        const response = await getHotel(userInfo.id);
        setHotel(response);
        history.push("/hotel/profile");
      }
      if (userInfo.role === "USER") {
        alert("You don't have permission!");
        throw new Error("You don't have permission");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const resetPassword = async (email) => {
    try {
      const redirectTo = `${process.env.REACT_APP_URI}/auth/change-password`;
      console.log(redirectTo);
      const { data, error } = await supabase.auth.resetPasswordForEmail(email, {
        redirectTo,
      });
      if (error) {
        console.log(error);
        return false;
      }
      console.log("this is data from reset password", data);
      return true;
    } catch (error) {
      console.log(error);
    }
  };

  const changePassword = async (password) => {
    try {
      console.log("Hello!!")
      const { data, error } = await supabase.auth.updateUser({
        password,
      });
      if (error) console.log(error);
      console.log("this is data from reset password", data);
    } catch (error) {
      console.log(error);
    }
  };

  return <AuthContext.Provider value={{ user, hotel, pms: pmsUser, logout, signIn, resetPassword, loading, changePassword, setHotel }}>{children}</AuthContext.Provider>;
}
