import { authenticatedApi } from "./base";

export const allPms = async () => {
  try {
    const authApi = await authenticatedApi();
    const { data } = await authApi.get(`${process.env.REACT_APP_SERVER_URI}/pms`);
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const addPms = async (data) => {
  const auth = await authenticatedApi();
  const { data: response } = await auth.post(`${process.env.REACT_APP_SERVER_URI}/pms`, data);
  console.log("this is response from connectPms", response);
  return response;
};

export const connectPms = async (data) => {
  try {
    const auth = await authenticatedApi();
    const { data: response } = await auth.post(`${process.env.REACT_APP_SERVER_URI}/hotels/connect-pms`, data);
    return response;
  } catch (error) {
    console.log("connectPms", error);
  }
};

export const getPmsUser = async (id) => {
  try {
    const auth = await authenticatedApi();
    const { data: response } = await auth.get(`${process.env.REACT_APP_SERVER_URI}/users/pms-user/${id}`);
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const connectStripe = async (data) => {
  try {
    const authApi = await authenticatedApi();
    const { data: response } = await authApi.post(`${process.env.REACT_APP_SERVER_URI}/pms/connect-stripe`, data);
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const updatePms = async (data) => {
  try {
    const auth = await authenticatedApi();
    const { data: response } = await auth.patch(`${process.env.REACT_APP_SERVER_URI}/pms/`);
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const deletePms = async (pmsId) => {
  const auth = await authenticatedApi();
  const { data: response } = await auth.delete(`${process.env.REACT_APP_SERVER_URI}/pms/${pmsId}`);
  return response;
};
